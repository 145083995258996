import React, { Suspense, lazy, useEffect, } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useCalendlyEventListener, } from "react-calendly";
import { GApageView, GAevent, } from '../config';
import { HelmetProvider } from "react-helmet-async";
import LinkedInTag from 'react-linkedin-insight';
import * as api from '../services/api';

import Spinner from '../components/Spinner';
import HomePage from '../containers/home';

const NotFound = lazy(() => import('../components/Notfound'));
const PluginPage = lazy(() => import('../containers/plugins'));
const ProductPage = lazy(() => import('../containers/products'));
const ProductDetailIndex = lazy(() => import('../containers/products/product.detail'));
const PageIndex = lazy(() => import('../containers/pages'));
const SupportPage = lazy(() => import('../containers/support'));
const SupportKBPage = lazy(() => import('../containers/support/support.knowledgebase'));
const SupportKBPageDetail = lazy(() => import('../containers/support/support.knowledgebase.page'));
const ContactPage = lazy(() => import('../containers/contact'));
const SignupPage = lazy(() => import('../containers/account.signup'));
const SignupDonePage = lazy(() => import('../containers/account.signup/done'));

const Routers = ({ ...props }) => {
    // const { locale, setCurrentLanguage } = props;
    // const additional_props = { locale, setCurrentLanguage };
    const additional_props = { GApageView, GAevent, };
    const cookie_settings = JSON.parse(localStorage.getItem("cookie_settings") || "{}");
    const cookie_consens = localStorage.getItem("cookie_consens") || false;

    // wake-up lambda API
    useEffect(() => {
        api.get('/public/plan/list')
            .then(response => { })
            .catch(error => {
            });
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useCalendlyEventListener({
        // onProfilePageViewed: () => console.log("onProfilePageViewed"),
        // onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        // onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) => {
            // console.log(e.data.payload);
            if (cookie_consens && cookie_settings.allow_marketing) {
                LinkedInTag.track(16019953);
                GAevent('CTA', 'succes_request_demo')
            }
            // if (typeof window.lintrk === 'function') {
            //     // window.lintrk('track', { conversion_id: 16019953 });
            //     LinkedInTag.track(16019953);
            // } else {
            //     console.warn("window.lintrk not a function!");
            // }
        }
    });

    return (
        <Suspense fallback={<Spinner />}>
            <HelmetProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" exact element={<HomePage {...additional_props} {...props} />} />
                        <Route path="plugins" exact element={<PluginPage {...additional_props} {...props} />} />
                        <Route path="products" exact element={<ProductPage {...additional_props} {...props} />} />
                        <Route path="product/:product" exact element={<ProductDetailIndex {...additional_props} {...props} />} />

                        <Route path="page/:slug" exact element={<PageIndex {...additional_props} {...props} />} />

                        <Route path="support" exact element={<SupportPage {...additional_props} {...props} />} />
                        <Route path="support/knowledgebase" exact element={<SupportKBPage {...additional_props} {...props} />} />
                        <Route path="contact" exact element={<ContactPage {...additional_props} {...props} />} />
                        <Route path="signup" exact element={<SignupPage {...additional_props} {...props} />} />
                        <Route path="signup/done" exact element={<SignupDonePage {...additional_props} {...props} />} />



                        <Route path="support/knowledgebase/:id/:slug" exact element={<SupportKBPageDetail {...additional_props} {...props} />} />
                        <Route path="*" element={<NotFound {...additional_props} {...props} />} />
                    </Routes>
                </BrowserRouter>
            </HelmetProvider>
        </Suspense>
    );
};

export default Routers;
