export const normalizeProgress = (progress, start, end) => {
    progress = ((progress - start) * 1) / (end - start);
    progress = Math.max(progress, 0);
    progress = Math.min(progress, 1);
    return progress;
};

/* used for .fading-text and .fading-visual scrolltriggers */
export const getFadingValues = (progress) => {

    // text
    let perc = normalizeProgress(progress, 0.15, 1) * 100;
    let p1 = 100;
    let p2 = 200;
    let angle = 135;
    if (perc < 33.3333) {
        p1 = -100 + perc * 6;
        p2 = perc * 6;
    } else if (perc > 80) {
        p1 = 100 - (perc - 80) * 10;
        p2 = 200 - (perc - 80) * 10;
        angle = 310;
    }

    // visualOpacity
    let visualOpacity = 0.2;
    if (progress < 0.4) {
        visualOpacity = 0.2 + 0.8 * (progress / 0.4);
    } else if (progress < 0.6) {
        visualOpacity = 1;
    } else {
        visualOpacity = 1 - 0.8 * ((progress - 0.6) / 0.4);
    }

    // general elementOpacity
    let elementOpacity = 0;
    if (progress < 0.333) {
        elementOpacity = progress * 3;
    } else if (progress < 0.7) {
        elementOpacity = 1;
    } else {
        elementOpacity = (1 - progress) * 5;
    }

    return { p1, p2, angle, visualOpacity, elementOpacity };
}
