import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { GraphQLClient } from 'graphql-request';
import { notification, } from 'antd';
import { useNavigate, } from 'react-router-dom';
import { PublicLayout } from './../../components/layouts';
import Menu from './menu';
import ScrollHint from '../../components/scroll-hint';

import SectionIntro from './section.intro';
import SectionVideo from './section.video';
import SectionWhichPlan from './section.wich.plan';
import Section0To100 from './section.0-100';
import SectionSteps from './section.steps';
import SectionWhichPlanSecond from './section.wich.plan.secondair';
import SectionAbout from './section.about';
import PageFooter from '../../components/footer/footer';
import Spinner from '../../components/Spinner';


const HomePage = ({
    ...props
}) => {

    const [pageDetails, setPageDetails] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const { locale } = props;
    const { height } = useWindowDimensions();
    const history = useNavigate();

    // getting page details from CMS
    useEffect(() => {
        const client = new GraphQLClient(process.env.REACT_APP_GCMS_URL);
        const requestHeaders = { 'gcms-locales': locale || 'nl' };
        const variables = {};
        const query = `{pagina(where: {paginaUrlSlug: "home"})
        {
            id
            paginaUrlSlug
            seoInformation {
                id
                seoDescription
                seoTitle
                seoImage(locales: en) {
                    id
                    url
                }
            }
            paginaHeader {
                ctabuttons {
                    id
                    buttonLabel
                    buttonUrl
                }
                pageHeaderImage(locales: en) {
                    id
                    url
                }
                pageHeaderSubTitleH2
                pageHeaderTitle
                pageHeaderContent {
                    html
                }
            }
            contentBlocks {
                id
                contentBlockInhoud {
                    html
                }
                contentBlockH2Title
                contentBlockAfbeelding(locales: en) {
                    id
                    url
                }
                ctabuttons {
                    buttonLabel
                    buttonUrl
                    id
                }
            }
        }}`;

        const fetchData = async () => {
            setDataLoaded(false);
            setPageDetails({});
            try {
                const data = await client.request(query, variables, requestHeaders);
                if (data.pagina) {
                    setPageDetails(data.pagina);
                    setDataLoaded(true);
                } else {
                    notification['warning']({
                        message: 'Fout bij ophalen van pagina',
                        description: "Deze pagina is niet gevonden of bestaat niet meer.",
                    });
                    history('/not-found');
                }

            } catch (error) {
                console.error('error: ', error);
            };
        };

        fetchData();
        // below line needed to remove the warning on build.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    };

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);
        return windowDimensions;
    }

    // useEffect(() => {
    //     if (height) {
    //         // alert('height change: ' + height);
    //     }
    // }, [height]);

    return (
        <PublicLayout {...props}>

            {!dataLoaded ? (<Spinner />
            ) : (
                <React.Fragment>
                    <Helmet>
                        <title>{pageDetails?.seoInformation?.seoTitle || process.env.REACT_APP_PAGE_NAME}</title>
                        <meta name="description" content={pageDetails?.seoInformation?.seoDescription || process.env.REACT_APP_META_DESCRIPTION} />
                        <meta name="keywords" content={pageDetails.seoInformation?.seoKeywords} />
                        <meta name="image" content="/images/share_preview.png" />
                        <link rel="canonical" href={`${process.env.REACT_APP_WEBSITE}`} />

                        {/* Other SM Meta Tags */}
                        <meta property="og:url" content={`${process.env.REACT_APP_WEBSITE}`} />
                        <meta property="og:title" content={pageDetails?.seoInformation?.seoTitle || process.env.REACT_APP_PAGE_NAME} />
                        <meta property="og:site_name" content={pageDetails?.seoInformation?.seoTitle || process.env.REACT_APP_PAGE_NAME} />
                        <meta property="og:description" content={pageDetails?.seoInformation?.seoDescription || process.env.REACT_APP_META_DESCRIPTION} />
                        <meta property="og:image" content="/images/share_preview.png" />

                        {/* Twitter meta tags */}
                        <meta property="twitter:url" content={`${process.env.REACT_APP_WEBSITE}`} />
                        <meta name="twitter:title" content={pageDetails?.seoInformation?.seoTitle || process.env.REACT_APP_PAGE_NAME} />
                        <meta name="twitter:description" content={pageDetails?.seoInformation?.seoDescription || process.env.REACT_APP_META_DESCRIPTION} />
                        <meta name="twitter:image" content="/images/share_preview.png" />
                    </Helmet>

                    <Menu title="Home" {...props}
                        history={history}
                    />

                    <React.Fragment>
                        <ScrollHint />
                        <main className="home">
                            <SectionIntro height={height} />
                            <SectionVideo height={height} />
                            <SectionWhichPlan height={height} />
                            <Section0To100 height={height} />
                            <SectionSteps pageSteps={pageDetails.contentBlocks} height={height} />
                            <SectionWhichPlanSecond height={height} />
                            <SectionAbout pageContent={pageDetails.paginaHeader} height={height} />
                            <div className="spacer-medium"></div>
                            {/* <InstagramPosts placement="home" height={height} /> */}
                            <div className="spacer-small"></div>
                        </main>
                        <PageFooter />
                    </React.Fragment>

                </React.Fragment>)}
        </PublicLayout>
    );
};

export default HomePage;