import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { FormattedMessage } from "react-intl";
import { normalizeProgress } from '../../components/helpers';
import MouseFollower from '../../components/helpers/mouse.follower';
import { NavLink } from 'react-router-dom';
import { Package } from '@phosphor-icons/react';

const SectionWhichPlanSecond = ({
    height,
}) => {

    gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);

    useEffect(() => {
        const element = ref.current;
        new MouseFollower(element.querySelector('.home-gallery-mousefollower'));
        const homeGallery = element.querySelector('section.home-gallery-vind-je-srs');
        const homeGalleryBackgroundImage = element.querySelector('section.home-gallery-vind-je-srs .background-image');

        const easings = {};

        // set a small timeout to give the dom same time to addust
        // otherwise the layout will not shown properly.
        setTimeout(() => {
            window.requestAnimationFrame(updateEasings);

            function updateEasings() {
                for (var key in easings) {
                    let easing = easings[key];
                    if (easing.value !== easing.target) {
                        easing.value += (easing.target - easing.value) * easing.speed;
                        if (Math.abs(easing.target - easing.value) < 0.002) {
                            easing.value = easing.target;
                        }
                        if (easing.hasOwnProperty('element')) {
                            easing.element.style.setProperty('--' + key, easing.value + easing.unit)
                        } else {
                            document.documentElement.style.setProperty('--' + key, easing.value + easing.unit)
                        }
                    }
                }
                window.requestAnimationFrame(updateEasings);
            }

            ScrollTrigger.defaults({
                // markers:true,
                start: "top top",
                end: "bottom top"
            });

            easings['home-gallery-background-opacity'] = {
                target: 0.2,
                value: 0.2,
                unit: '',
                speed: 0.07,
                element: homeGalleryBackgroundImage,
            };


            ScrollTrigger.create({
                id: 'home-gallery-vind-je-srs',
                trigger: '.home-gallery-scrolltrigger',
                onEnter: self => {
                    homeGallery.classList.add('started', 'active');
                },
                onUpdate: self => {
                    easings['home-gallery-background-opacity'].target = normalizeProgress(self.progress, 0, 0.5);
                    homeGallery.classList.toggle('home-gallery-text-visible', self.progress > 0.3);
                },
                onLeave: self => {
                    homeGallery.classList.add('has-left');
                    homeGallery.classList.remove('active');
                },
                onEnterBack: self => {
                    homeGallery.classList.remove('has-left');
                    homeGallery.classList.add('started', 'active');
                },
                onLeaveBack: self => {
                    homeGallery.classList.remove('active');
                },
            });
        }, 250);

    }, [height]);

    return (

        <>
            <div className="home-gallery-scrolltrigger"></div>
            <section data-section="which-plan-past-bij-jouw-secondair" ref={ref}>
                <section className="home-gallery-vind-je-srs">
                    <NavLink to="/products">
                        <div className="home-gallery-mousefollower text-center">
                            <div className="mouse-cursor">
                                <Package size={45} />
                            </div>
                            <div className="label">
                                <FormattedMessage id="check-our-plans" />
                            </div>
                        </div>
                    </NavLink>
                    <div className="background-image"></div>
                    <div className="text-center">
                        <h2><FormattedMessage id="welk-plan-past-bij-jou" /></h2>
                        <a href="/products" className="d-lg-none btn-srs-trans-outlined gallery-btn"><FormattedMessage id="discover" /></a>
                    </div>
                </section>
            </section>
        </>
    );
};

export default SectionWhichPlanSecond;
