import React from "react";
import Spinner from './../Spinner';

import { Layout } from 'antd';
// const { Content } = Layout;

const PublicLayout = ({
    loading = false,
    children,
    className,
}) => {

    if (loading) {
        return (<Spinner />);
    } else {
        return (
            <Layout className={`public-container bg-whitee ${className}`}>
                {children}

                {/* <Content style={{ padding: '0' }} className={`public-container ${className}`}>
                    {children}
                </Content> */}
            </Layout>
        );
    }

};

export default PublicLayout;