import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
// import MouseFollower from '../../components/helpers/mouse.follower';
import { FormattedMessage } from "react-intl";

// import SVGPlay from '../../assets/svg/play.svg';
// import SVGClose from '../../assets/svg/video-close.svg';

const SectionVideo = ({
    height
}) => {

    gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);

    useEffect(() => {
        const element = ref.current;
        const homeVideo = element.querySelector('.home-video');
        // new MouseFollower(element.querySelector('.home-video-mousefollower'));
        let homeVideoTimeout;

        // set a small timeout to give the dom same time to addust
        // otherwise the layout will not shown properly.
        setTimeout(() => {
            ScrollTrigger.defaults({
                // markers:true,
                start: "top top",
                end: "bottom top"
            });

            ScrollTrigger.create({
                id: 'home-video',
                trigger: '.home-video-scrolltrigger',
                onEnter: self => {
                    clearTimeout(homeVideoTimeout);
                    homeVideo.classList.add('started', 'active');

                },
                onLeave: self => {
                    homeVideo.classList.add('has-left');
                    homeVideoTimeout = setTimeout(() => {
                        homeVideo.classList.remove('started', 'active');
                    }, 500);

                    // if (homeVideo.classList.contains('is-playing')) {
                    //     toggleHomeVideo();
                    // }
                },
                onEnterBack: self => {
                    clearTimeout(homeVideoTimeout);
                    homeVideo.classList.add('started', 'active');
                    homeVideo.classList.remove('has-left');
                },
                onLeaveBack: self => {
                    homeVideoTimeout = setTimeout(() => {
                        homeVideo.classList.remove('started', 'active');
                    }, 500);

                    // if (homeVideo.classList.contains('is-playing')) {
                    //     toggleHomeVideo();
                    // }
                },
            });
        }, 250);

        // clean-up
        return () => {
            document.removeEventListener('mousemove', element.querySelector('.home-video-mousefollower'));
        };

    }, [height]);

    // toggle the video player, from background to overview.
    // const toggleHomeVideo = () => {
    //     const element = ref.current;
    //     element.querySelector('.home-video').classList.toggle('is-playing');
    //     const homeBackgroundVideo = element.querySelector('section.home-video .background-video');

    //     if (element.querySelector('.home-video').classList.contains('is-playing')) {
    //         homeBackgroundVideo.innerHTML = '<iframe class="" width="100%" height="100%" src="https://www.youtube.com/embed/bpIyX_xPbbc?autoplay=1" title="YouTube - SRS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
    //     } else {
    //         // homeBackgroundVideo.innerHTML = '<video src="/video/srs-life-teaser-loop-HQ.mp4" autoplay loop muted playsinline></video>';
    //         homeBackgroundVideo.innerHTML = '<iframe class="background" src="//player.vimeo.com/video/571358470?background=1" width="100%" frameborder="0"/>';
    //     }
    // };

    return (
        <section data-section="video" ref={ref}>
            <div className="home-video-scrolltrigger"></div>
            <section className="home-video">
                {/* <div
                    // onClick={toggleHomeVideo}
                    className="home-video-mousefollower">
                    <img src={SVGPlay} alt="" />
                    <div className="label"><FormattedMessage id="video-discover-SRS" /></div>
                </div> */}

                {/* <div
                    // onClick={toggleHomeVideo}
                    className="video-close">
                    <img src={SVGClose} alt="Stop video" />
                </div> */}

                {/* <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1017909698?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Smart Rental Solutions"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */}

                <div className="background-video">
                    <iframe title="SRS loop" className="background"
                        src="//player.vimeo.com/video/1017909698?background=1&byline=0"
                        width="100%"
                        frameBorder="0"
                    // webkitallowfullscreen mozallowfullscreen allowfullscreen
                    />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center text-xl-start ps-xl-5 pb-xl-5 d-flex flex-column justify-content-xl-end justify-content-center">
                            <h1>Smart Rental Solutions</h1>
                            <h2 className="mb-4 mb-xl-0"><FormattedMessage id="video-discover-tagline" /></h2>
                            {/* <div className="btn-box"><div onClick={toggleHomeVideo} className="btn-srs-trans-outlined d-xl-none cursor"><FormattedMessage id="discover-SRS" /></div></div> */}
                        </div>
                    </div>
                </div>
            </section>

        </section>
    );
};

export default SectionVideo;
