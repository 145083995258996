import React, { useRef, useEffect } from 'react';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { getFadingValues, normalizeProgress } from '../../components/helpers';
import LogoHomepage from '../../assets/images/Logo_SmartRentalSoltution_diap.png';

const SectionIntro = ({
    height
}) => {

    gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);

    function getHomeStartFadingTextValues(progress) {
        let perc = normalizeProgress(progress, 0, 0.8) * 100;
        let p1 = 100;
        let p2 = 200;
        let angle = 135;
        p1 = -100 + perc * 2; // -100 tot 0
        p2 = perc * 2; // 0 tot 200
        return { p1, p2, angle };
    }

    gsap.utils.toArray('.fading-text').forEach((element, i) => {
        ScrollTrigger.create({
            trigger: element,
            start: 'top bottom',
            end: 'top top',
            onUpdate: self => {
                let values = getFadingValues(self.progress);
                self.trigger.style.setProperty('--gradient-progress', values.p1 + '%');
                self.trigger.style.setProperty('--gradient-progress-2', values.p2 + '%');
                self.trigger.style.setProperty('--gradient-angle', values.angle + 'deg');
                self.trigger.style.setProperty('--fading-opacity', values.opacity);
            },
            // markers: true,
        });
    });

    useEffect(() => {
        const element = ref.current;
        // const homeStartTitle = element.querySelector('.home-start .fading-text');
        const homeStartTitle = element.querySelector('.home-start h1');
        const homeStart = element.querySelector('.home-start');
        const homeStartBackground = element.querySelector('.home-start .background-image');
        const easings = {};


        // set a small timeout to give the dom same time to addust
        // otherwise the layout will not shown properly.
        setTimeout(() => {
            window.requestAnimationFrame(updateEasings);
            function updateEasings() {
                for (var key in easings) {
                    let easing = easings[key];
                    if (easing.value !== easing.target) {
                        easing.value += (easing.target - easing.value) * easing.speed;
                        if (Math.abs(easing.target - easing.value) < 0.002) {
                            easing.value = easing.target;
                        }
                        if (easing.hasOwnProperty('element')) {
                            easing.element.style.setProperty('--' + key, easing.value + easing.unit)
                        } else {
                            document.documentElement.style.setProperty('--' + key, easing.value + easing.unit)
                        }
                    }
                }
                window.requestAnimationFrame(updateEasings);
            }

            ScrollTrigger.defaults({
                // markers:true,
                start: "top top",
                end: "bottom top"
            });

            easings['home-start-background-scale'] = {
                target: 1,
                value: 1,
                unit: '',
                speed: 0.07,
                element: homeStartBackground,
            };
            easings['home-start-background-opacity'] = {
                target: 0,
                value: 0,
                unit: '',
                speed: 0.07,
                element: homeStartBackground,
            };

            ScrollTrigger.create({
                id: 'home-start',
                trigger: '.home-start-scrolltrigger',
                onUpdate: self => {
                    let values = getHomeStartFadingTextValues(self.progress);
                    homeStartTitle.style.setProperty('opacity', 1);
                    homeStartTitle.style.setProperty('--gradient-progress', values.p1 + '%');
                    homeStartTitle.style.setProperty('--gradient-progress-2', values.p2 + '%');
                    homeStartTitle.style.setProperty('--gradient-angle', values.angle + 'deg');
                    easings['home-start-background-scale'].target = 0.5 + normalizeProgress(self.progress, 0, 1) * 0.8;
                    easings['home-start-background-opacity'].target = self.progress;
                },
                onEnter: self => {
                    homeStart.classList.add('active');
                },
                onLeave: self => {
                    homeStart.classList.add('has-left');
                    homeStart.classList.remove('active');
                },
                onEnterBack: self => {
                    homeStart.classList.remove('has-left');
                    homeStart.classList.add('active');
                },
                onLeaveBack: self => {
                    homeStart.classList.remove('active');
                },
                // markers: true,
            });

        }, 250);
    }, [height]);


    return (
        <section data-section="header - intro" ref={ref}>

            <div className="home-start-scrolltrigger"></div>

            <section className="home-start">
                <div className="background-image"></div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col d-flex justify-content-center align-items-center flex-column text-center">
                            <img src={LogoHomepage} alt="SRS Logo" />
                            <h1 className="">Mobility made easy</h1>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default SectionIntro;