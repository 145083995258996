
class MouseFollower {
    constructor(el) {
        this.el = el;
        this.target = {
            x: 0,
            y: 0,
        };
        this.current = {
            x: 0,
            y: 0,
        };
        this.init();
        this.update();
    }

    init() {
        var self = this;

        document.addEventListener('mousemove', function (event) {
            self.target.x = event.clientX;
            self.target.y = event.clientY;
        });
    }

    // remove() {
    //     var self = this;

    //     document.removeEventListener('mousemove', function (event) {
    //         self.target.x = event.clientX;
    //         self.target.y = event.clientY;
    //     });
    // }

    update() {
        // if (isMobile) return false;
        if (Math.abs(this.target.x - this.current.x) < 0.1) { this.current.x = this.target.x; }
        if (Math.abs(this.target.y - this.current.y) < 0.1) { this.current.y = this.target.y; }

        this.current.x += (this.target.x - this.current.x) * 0.07;
        this.current.y += (this.target.y - this.current.y) * 0.07;

        this.el.style.transform = 'translate(' + this.current.x + 'px, ' + this.current.y + 'px)';

        window.requestAnimationFrame(this.update.bind(this));
    }
}

export default MouseFollower;