import React from "react";
import { Modal, Result, Button, } from 'antd';
import { FormattedMessage, } from "react-intl";
import { Cookie, } from "@phosphor-icons/react";

const CookieInfoModal = ({
    modalIsOpen,
    modalIsOpenHandler,

}) => {


    return (

        <Modal
            open={modalIsOpen}
            title={<><Cookie size={20} weight="light" /> <FormattedMessage id="cookie-information" /></>}
            width={700}
            onCancel={() => modalIsOpenHandler(false)}
            footer={[
            ]}>

            <Result
                icon={<Cookie size={70} weight="thin" />}
                // title={<FormattedMessage id="contact-success" />}
                subTitle={<div style={{ textAlign: "left" }}><FormattedMessage id="cookie-addintional-information" /></div>}
                extra={[
                    <Button onClick={e => modalIsOpenHandler(false)} key="close"><FormattedMessage id="close" /></Button>,
                ]}
            />

        </Modal>
    );
};


export default CookieInfoModal;
