import React, { useEffect, useState, } from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { detectBrowserlanguage, } from './services/language';
import { notification, Button, Row, Col, Space, } from 'antd';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ArrowsClockwise, } from '@phosphor-icons/react';
import Routers from './routes';
import LinkedInTag from 'react-linkedin-insight';
import messages_nl from "./translations/nl.json";
import messages_en from "./translations/en.json";
import messages_de from "./translations/de.json";

const App = () => {

    const [LanguageObject, setLanguageObject] = useState({});
    const [currentLanguage, setCurrentLanguage] = useState(detectBrowserlanguage('en') || 'en');
    const [loading, setLoading] = useState(true);
    const [newVersion, setNewVersion] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const cookie_settings = JSON.parse(localStorage.getItem("cookie_settings") || "{}");
    const cookie_consens = localStorage.getItem("cookie_consens") || false;

    if (cookie_consens && cookie_settings.allow_marketing) {
        LinkedInTag.init(5454058, undefined, false);
    }

    useEffect(() => {
        setLanguageObject({
            messages: {
                en: messages_en,
                nl: messages_nl,
                de: messages_de,
            },
        }, setLoading(false));
    }, []);

    useEffect(() => {
        // openNotification();

        // Set timeout to make sure view has renderd.
        setTimeout(() => {
            if (newVersion) {
                openNotification();
            }
        }, 1500);

        // eslint-disable-next-line
    }, [newVersion]);

    const openNotification = () => {
        api.open({
            message: <strong><FormattedMessage id="new-version" /></strong>,
            type: 'info',
            description: <Row>
                <Col span={24}>
                    <FormattedMessage id="new-version-info" />
                </Col>
            </Row>,
            className: 'custom-class',
            style: { width: 600, border: '1px solid #fff' },
            placement: 'bottomLeft',
            duration: 0,
            key: 1,
            btn: (
                <Space>
                    <Button size="small" onClick={e => window.location.reload()}><ArrowsClockwise size={16} className="mr-1" /> Refresh</Button>
                </Space>),
        });
    };

    serviceWorkerRegistration.register({
        onUpdate: registration => {
            const waitingServiceWorker = registration.waiting;

            if (waitingServiceWorker) {
                waitingServiceWorker.addEventListener("statechange", event => {
                    if (event.target.state === "activated") {
                        setNewVersion(true);
                    }
                });
                waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
            }
        },
    });

    return (
        <React.Fragment>
            {loading ? (<p>loading language settings</p>) : (
                <IntlProvider locale={currentLanguage} messages={LanguageObject.messages[currentLanguage]}>
                    <Routers locale={currentLanguage} setCurrentLanguage={setCurrentLanguage}></Routers>
                    {/* Notification placeholder */}
                    {contextHolder}
                </IntlProvider>
            )}
        </React.Fragment>
    );
}

export default App;