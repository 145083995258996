export const fallbackLanguage = "en";       // default fallback language
export const languages = ["en", "nl"];      // list with supported languages

// Check if we have a support langauge, if not return the fallback
export const validateLanguage = (lang) => {
    return languages.includes(lang) ? lang : fallbackLanguage;
};

export const detectBrowserlanguage = (defaultLang) => {
    if (typeof navigator === 'object') {
        let navLang = navigator.language || navigator.userLanguage || defaultLang || fallbackLanguage;
        return validateLanguage(navLang.substring(0, 2));
    };
};