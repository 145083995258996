import React, { useRef } from 'react';
// import { FormattedMessage } from "react-intl";
// import gsap from 'gsap';
// import ScrollTrigger from 'gsap/ScrollTrigger';
import Screen from './steps/screen';
import { ScrollTriggerProvider } from './steps/ScrollTriggerProvider';

// import { getFadingValues, normalizeProgress } from '../../components/helpers';

const SectionSteps = ({
    pageSteps,
    height,
    ...props
}) => {

    const ref = useRef(null);
    const SHOW_MARKERS = false;

    // function setActiveStap(nr) {
    //     let el = document.getElementById('stap-teller-display');
    //     console.log('el: ', el);
    //     if (el) {
    //         el.innerHTML = '0' + (nr + 1);
    //     }
    // };

    return (
        <section data-section="steps" ref={ref}>

            <section className="stappen">
                {/* <div className="container stap-teller-fixed">
                    <div id="stap-teller-display">{activeStep}</div>
                </div> */}

                <div className="container">

                    {pageSteps.map((step, index) => (
                        <ScrollTriggerProvider
                            key={index}
                            debug={SHOW_MARKERS}
                        >

                            <Screen
                                colorStart="#333399"
                                colorEnd="#663399"
                                fadeIn={false}
                                data={step}
                                index={index}
                            />

                        </ScrollTriggerProvider>
                    ))}

                </div>
            </section>

        </section>
    );
};

export default SectionSteps;