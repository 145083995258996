import React, { useEffect, useState, } from 'react';
import { FormattedMessage, } from "react-intl";
import { notification, Row, Col, Button, Space, Form, Switch, Tooltip, Input, Popover, } from 'antd';
import { useForm, Controller, } from "react-hook-form";
import { Link, } from 'react-router-dom';
import * as api_post from '../../services/api';

import { ArrowRight, CheckCircle, Cookie, EnvelopeSimple, Gear, Headset, Info, LinkedinLogo, Phone, X, YoutubeLogo, } from '@phosphor-icons/react';
import CookieInfoModal from './cookie.information.modal';
import SRSLogo from './../../assets/images/Logo_SmartRentalSoltution_no_wheel.png';

const PageFooter = ({
    setIsContactModalOpen,
    isContactModalOpen,
    ...props

}) => {

    const [cookieInfoOpen, setCookieInfoOpen] = useState(false);
    const [formSend, setFormSend] = useState(false);
    const { branding_name, branding_phone, GAevent, } = props;
    const [api, contextHolder] = notification.useNotification();
    const { handleSubmit, control, reset, } = useForm({ mode: "all" });
    const newsletterform = useForm({ mode: "all" });
    const { errors, } = newsletterform.formState;

    // check cookie settings
    useEffect(() => {
        let isCookieBannerOpen = false;
        if ("cookie_consens" in localStorage) {
            if (localStorage.getItem("cookie_consens")) {
                isCookieBannerOpen = false;
            } else {
                isCookieBannerOpen = true;
            }
        } else {
            isCookieBannerOpen = true;
        }

        if (isCookieBannerOpen) {
            openCookieBanner();
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const cookie_settings = JSON.parse(localStorage.getItem("cookie_settings") || "{}");
        reset(cookie_settings);
        // eslint-disable-next-line
    }, []);

    // Jira service manager widget
    useEffect(() => {

        // const script = document.createElement('script');
        // script.src = "https://jsd-widget.atlassian.com/assets/embed.js";
        // script.async = true;
        // script.setAttribute('data-jsd-embedded', '');
        // script.setAttribute('data-key', '8b9c6a9a-38c4-49cb-8e4c-f77b38d7236d');
        // script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');

        // document.body.appendChild(script);

        // return () => {
        //     // Remove the script on component unmount
        //     document.body.removeChild(script);
        // };
    }, []);

    const openCookieBanner = () => {
        api.open({
            message: <strong><FormattedMessage id="cookie-banner" /></strong>,
            icon: <Cookie size={24} />,
            description: <Row>
                <Col span={24}>
                    <FormattedMessage id="cookie-banner-info" />
                </Col>
            </Row>,
            className: 'cookie-banner',
            style: { width: 800, border: '1px solid #fff' },
            placement: 'bottomLeft',
            duration: 0,
            key: 1,
            btn: (
                <Space>
                    <Button
                        variant="outlined"
                        onClick={e => openCookieBannerSettings()}><Gear size={20} /></Button>
                    <Tooltip title={<FormattedMessage id="cookie-additional-info" />}>
                        <Button
                            variant="outlined"
                            onClick={e => setCookieInfoOpen(!cookieInfoOpen)}><Info size={20} /></Button>
                    </Tooltip>
                    <Button className="ant-btn-success" onClick={e => cookieSet()}><CheckCircle size={16} className="mr-1" /> Akkoord</Button>
                </Space>),
        });
    };

    const openCookieBannerSettings = () => {
        api.open({
            message: <strong><FormattedMessage id="cookie-banner" /></strong>,
            icon: <Cookie size={24} />,
            description: <Row>
                <Col span={24}>
                    <FormattedMessage id="cookie-banner-info" />
                </Col>
                <Col span={24} className="mt-2">
                    <strong>
                        <FormattedMessage id="cookie-banner-settings-info" />
                    </strong>
                </Col>
                <Col span={24} className="mt-4">
                    <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                    // layout="vertical"
                    >

                        <Controller
                            control={control}
                            defaultValue={true}
                            name="allow_marketing"
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { error },
                            }) => (
                                <Form.Item
                                    labelAlign='left'
                                    label={<strong>Marketing & Advertisment</strong>}>
                                    <Switch
                                        onChange={onChange}
                                        checked={value}
                                        className="mr-1"
                                    /> <small><FormattedMessage id="cookie-marketing-info" /></small>
                                </Form.Item>
                            )}
                        />

                        <Controller
                            control={control}
                            defaultValue={true}
                            name="allow_traffic"
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { error },
                            }) => (
                                <Form.Item
                                    labelAlign='left'
                                    label={<strong>Traffic & Performance</strong>}>
                                    <Switch
                                        onChange={onChange}
                                        checked={value}
                                        className="mr-1"
                                    /> <small><FormattedMessage id="cookie-traffic-info" /></small>
                                </Form.Item>
                            )}
                        />
                    </Form>
                </Col>
            </Row>,
            className: 'cookie-banner',
            style: { width: 800, border: '1px solid #fff' },
            placement: 'bottomLeft',
            duration: 0,
            key: 1,
            btn: (
                <Space>
                    <Button
                        variant="outlined"
                        onClick={e => openCookieBanner()
                        }> <X size={20} /></Button >
                    <Tooltip title={<FormattedMessage id="cookie-additional-info" />}>
                        <Button
                            variant="outlined"
                            onClick={e => setCookieInfoOpen(!cookieInfoOpen)}><Info size={20} /></Button>
                    </Tooltip>
                    <Button className="ant-btn-success" onClick={handleSubmit(cookieSet)}><CheckCircle size={16} /> <FormattedMessage id="save" /></Button>
                </Space >),
        });
    };

    const cookieSet = (data) => {
        localStorage.setItem('cookie_consens', true);
        if (data) {
            localStorage.setItem('cookie_settings', JSON.stringify(data));
        } else {
            localStorage.setItem('cookie_settings', JSON.stringify({ "allow_marketing": true, "allow_traffic": true }));
        }
        api.destroy();
        window.location.reload();
    };

    const submitNewsletterSignup = (data) => {
        var first_name = data.user_name.split(' ')[0];
        var last_name = data.user_name.replace(first_name, '');

        const post_data = {
            firstname: first_name,
            lastname: last_name,
            email: data.user_email,
        }
        GAevent('CTA', "Signup for newsletter success");
        api_post.post('/mailler/news_letter', post_data).then(response => {
            if (response.data.status) {
                setFormSend(true);
            }
        });

    };

    return (
        <footer className="main__footer p_relative">
            <div className="main__footer__top">
                <div className="footer__middle  p_relative d_block">
                    <div className="auto_container">
                        <div className="footer__middle__content">
                            <div className="row clearfix">

                                {/*  First block, logo and info */}
                                <div className="col-lg-4 col-md-6 col-sm-12 footer_column">
                                    <div className="footer_widget left">
                                        <div className="footer__logo">
                                            <figure style={{ width: "150px" }}>
                                                <img src={SRSLogo} alt="" />
                                            </figure>
                                        </div>

                                        <div className="widget_content">
                                            <p>Smart Rental Solutions is dé alles-in-een oplossing om je bedrijfsprocessen te automatiseren. Onze software is met name geschikt voor:<br />
                                                Verhuur & Lease</p>
                                        </div>
                                        <div className="widget_media">
                                            <ul>
                                                <li><a target="_blank"
                                                    rel="noreferrer"
                                                    href="https://www.linkedin.com/company/94174020/admin/feed/posts/"><LinkedinLogo size={45} weight='light' /></a></li>
                                                <li><a target="_blank"
                                                    rel="noreferrer"
                                                    href="https://www.youtube.com/@SmartRentalSolutions"><YoutubeLogo size={45} weight='light' /></a></li>
                                                <li><a href="tel:+31882026161"><Phone size={40} weight='light' /></a></li>
                                                <li><a href="mailto:info@smartrentalsolutions.nl"><EnvelopeSimple size={40} weight='light' /></a></li>
                                                <li><Link
                                                    to="/support"><Headset size={40} weight='light' /></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* Navigation menu */}
                                <div className="col-lg-2 col-md-6 col-sm-12 footer_column">
                                    <div className="footer_widget links_widget ml_100">
                                        <div className="widget_title">
                                            <h4>Menu</h4>
                                        </div>
                                        <div className="widget_content">
                                            <ul className="links_list clearfix">
                                                <li><Link to="/page/productverhuur"><FormattedMessage id="product-rental" /></Link></li>
                                                <li><Link to="/page/autoverhuurbedrijf-beginnen"><FormattedMessage id="car-rental-company" /></Link></li>
                                                <li><Link to="/page/vastgoed-verhuur"><FormattedMessage id="property-rental" /></Link></li>
                                                <li><Link to="/products"><FormattedMessage id="rental-brances" /></Link></li>
                                                <li><a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href="https://smartrentalsolutions.freshdesk.com/support/home"><FormattedMessage id="support" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* About us menu */}
                                <div className="col-lg-2 col-md-6 col-sm-12 footer_column">
                                    <div className="footer_widget links_widget">
                                        <div className="widget_title">
                                            <h4><FormattedMessage id="about-us" /></h4>
                                        </div>
                                        <div className="widget_content">
                                            <ul className="links_list clearfix">
                                                <li><Link to="/page/over-ons"><FormattedMessage id="who-are-we" /></Link></li>
                                                <li><Link to="/page/werkenbij"><FormattedMessage id="werken-bij" /></Link></li>
                                                <li><Link to="/page/partners"><FormattedMessage id="our-partners" /></Link></li>
                                                <li><Link to="/page/demo-srs"><FormattedMessage id="free-demo" /></Link></li>
                                                <li><Link to="/page/adviesgesprek"><FormattedMessage id="adviesgesprek" /></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* News letter */}
                                <div className="col-lg-4 col-md-6 col-sm-12 footer_column">
                                    <div className="footer_widget news__letter">
                                        <div className="widget_title">
                                            <h4><FormattedMessage id="news-letter" /></h4>
                                        </div>
                                        <div className="widget_content">
                                            <p><FormattedMessage id="news-letter-info" /></p>
                                            <div className="news__letter">
                                                {!formSend ? (
                                                    <form>
                                                        <Controller
                                                            control={newsletterform.control}
                                                            name="user_name"
                                                            defaultValue=""
                                                            rules={{
                                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                                            }}
                                                            render={({
                                                                field: { onChange, onBlur, value, name, ref },
                                                                fieldState: { error },
                                                            }) => (
                                                                <FormattedMessage id="your-name">
                                                                    {NameField =>
                                                                        <Input
                                                                            placeholder={NameField}
                                                                            onChange={onChange}
                                                                            value={value} />
                                                                    }</FormattedMessage>
                                                            )} />

                                                        <Controller
                                                            control={newsletterform.control}
                                                            name="user_email"
                                                            defaultValue=""
                                                            rules={{
                                                                required: { value: true, message: "inputfield-FieldIsRequired" },
                                                                pattern: {
                                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                                                                    message: "EmailAddressNotValid",
                                                                },
                                                            }}
                                                            render={({
                                                                field: { onChange, onBlur, value, name, ref },
                                                                fieldState: { error },
                                                            }) => (
                                                                <FormattedMessage id="emailaddress">
                                                                    {NameField =>
                                                                        <Input
                                                                            status="error"
                                                                            className="mt-1"
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            placeholder={NameField} />
                                                                    }</FormattedMessage>
                                                            )} />

                                                        <Popover
                                                            content={<div className="p-2">
                                                                {errors?.user_name?.message && (
                                                                    <p className="text-danger"><FormattedMessage id="your-name" />: <FormattedMessage id={errors?.user_name?.message} /></p>
                                                                )}

                                                                {errors?.user_email?.message && (
                                                                    <p className="text-danger"><FormattedMessage id="email-address" />: <FormattedMessage id={errors?.user_email?.message} /></p>
                                                                )}
                                                            </div>}
                                                            title={<strong><FormattedMessage id="form-error" /></strong>}
                                                            trigger={false}
                                                            open={errors.user_name || errors.user_email}
                                                            placement="bottom">
                                                            <button
                                                                className="submit"
                                                                onClick={newsletterform.handleSubmit(submitNewsletterSignup)}><ArrowRight size={28} /> </button>
                                                        </Popover>
                                                    </form>
                                                ) : (
                                                    <div><h4><CheckCircle className="text-success" size={50} /> Succesvol ingeschreven</h4></div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer_bottom p_relative">

                <div className="auto_container">
                    <Row>
                        <Col xl={6}>
                            <p>© {new Date().getFullYear()}, Smart Rental Solutions B.V.</p>
                        </Col>

                        <Col xl={16}>
                            <Row align="middle" justify="center">
                                <Col xl={6} md={12} sm={24} xs={24}><p><a href="/files/algemene_voorwaarden_srs.pdf" aria-label="Download onze voorwaarden" rel="noreferrer" target="_blank"><FormattedMessage id="footer-terms" /></a></p></Col>
                                <Col xl={6} md={12} sm={24} xs={24}><p><a href="/files/privacy_statement_srs.pdf" aria-label="Download onze privacy statement" rel="noreferrer" target="_blank"><FormattedMessage id="footer-privacy" /></a></p></Col>
                                <Col xl={6} md={12} sm={24} xs={24}><p className="pointer" onClick={openCookieBanner} aria-label={<FormattedMessage id="cookie-settings" />}><FormattedMessage id="cookie-settings" /></p></Col>
                                <Col xl={6} md={12} sm={24} xs={24}><p className="pointer" onClick={e => setCookieInfoOpen(!cookieInfoOpen)} aria-label={<FormattedMessage id="cookie-policy" />}><FormattedMessage id="cookie-policy" /></p></Col>
                            </Row>
                        </Col>
                    </Row>

                    <div className="bottom_inner  p_relative">
                        <div className="company__name">
                            <p>{branding_name} {branding_phone}</p>
                        </div>
                    </div>
                </div>
            </div>


            {/* Cookie banner placeholder */}
            {contextHolder}

            {cookieInfoOpen && (
                <CookieInfoModal
                    modalIsOpen={cookieInfoOpen}
                    modalIsOpenHandler={setCookieInfoOpen}
                />
            )}

            {/* <script data-jsd-embedded data-key="8b9c6a9a-38c4-49cb-8e4c-f77b38d7236d" data-base-url="https://jsd-widget.atlassian.com" src="https://jsd-widget.atlassian.com/assets/embed.js"></script> */}
        </footer>
    );
};

export default PageFooter;