import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import App from './App';

import './assets/styles/plugin.style.css';
// import './assets/styles/flaticon_xyz.css';
import './assets/styles/global.css';
import './assets/styles/style.css';
import './assets/styles/responsive.css';
import './assets/styles/spinner.style.css';
import './assets/styles/color.css';

import './assets/styles/app.css';
import './assets/styles/app-overwrite.css';
import './assets/styles/home.css';
import './assets/styles/menu.css';
import './assets/styles/images.css';
import './assets/styles/registration.css';


const container = document.getElementById('root');
const root = createRoot(container);
const defaultData = {
    borderRadius: 3,
    colorPrimary: '#ff7100', //'#1ebbd6',
    colorPrimaryHover: '#46005C', //'#118093',
    itemSelectedColor: '#ff7100',
    hashed: false,
};

root.render(<React.StrictMode>
    <ConfigProvider
        csp={{ nonce: `${process.env.REACT_APP_GENERATED_NONCE_VALUE}` }}
        // csp={{ nonce: 'YourNonceCode' }}
        theme={{
            token: {
                colorPrimary: defaultData.colorPrimary,
                colorPrimaryHover: defaultData.colorPrimaryHover,
                itemSelectedColor: defaultData.itemSelectedColor,
                borderRadius: 2,
                colorText: '#fff',
                colorTextSecondary: '#fff',
                colorTextTertiary: '#fff',
                // colorTextQuaternary: '#fff',
                colorBgLayout: 'rgba(26, 26, 26, 0.9)',
                colorBgElevated: 'rgba(26, 26, 26, 0.7)',
                // colorBgSpotlight: '#010101',
            },
            // hashed: false,
        }}>
        <App />
    </ConfigProvider>
</React.StrictMode>);
// root.render(<App />);
