import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'
import Hotjar from '@hotjar/browser';

export const SERVER = process.env.REACT_APP_SERVER;
export const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE;
export const GOOGLE_TAG_MANGER = process.env.REACT_APP_GOOGLE_TM;
export const HOTJAR_SITE_ID = process.env.REACT_APP_HOTJAR_SITE_ID;
export const HOTJAR_VERSION = process.env.REACT_APP_HOTJAR_VERSION;

const cookie_settings = JSON.parse(localStorage.getItem("cookie_settings") || "{}");
const cookie_consens = localStorage.getItem("cookie_consens") || false;

// export const APP_DOMAIN = window.location.host === 'localhost:3000' ? 'smartrentalsolutions.nl' : window.location.host;

if (cookie_consens) {
    if (cookie_settings.allow_traffic) {
        // console.log('Traffic collector loaded...');
        ReactGA.initialize(GOOGLE_ANALYTICS, { debug: true });
        Hotjar.init(HOTJAR_SITE_ID, HOTJAR_VERSION);
    }
    if (cookie_settings.allow_marketing) {
        // console.log('Marketing trackers loaded...');
        TagManager.initialize({ gtmId: GOOGLE_TAG_MANGER });
    }
}
else { console.log('Trackers NOT loaded...'); }


export const GApageView = (page) => {
    // ReactGA.send(page);
    ReactGA.send({ hitType: "pageview", page: page });
};

// export const GAmodalView = (modal) => {
//     ReactGA.modalview(modal);
// };

export const GAevent = (category, action) => {
    ReactGA.event({
        category: category,
        action: action,
        // label: "your label", // optional
        // value: 99, // optional, must be a number
        // nonInteraction: true, // optional, true/false
        // transport: "xhr", // optional, beacon/xhr/image
    });
};


// eslint-disable-next-line no-extend-native
Date.prototype.addYears = function (years) {
    var date = new Date(this.valueOf());
    date.setFullYear(date.getFullYear() + years)
    return date;
};


// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addHours = function (hours) {
    var date = new Date(this.valueOf());
    date.setHours(date.getHours() + hours);
    return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addMinutes = function (minutes) {
    var date = new Date(this.valueOf());
    date.setMinutes(date.getMinutes() + minutes);
    return date;
};