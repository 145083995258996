import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { FormattedMessage } from "react-intl";
import { normalizeProgress } from '../../components/helpers';
import { ArrowFatLinesDown } from '@phosphor-icons/react';
// import MouseFollower from '../../components/helpers/mouse.follower';

// import { ReactComponent as SVGPlay } from '../../assets/svg/play.svg';

const SectionWhichPlan = ({
    height
}) => {

    gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);

    useEffect(() => {
        const element = ref.current;
        const bgZoom = element.querySelector('section.bg-zoom');
        const bgZoomBackground = element.querySelector('section.bg-zoom .background-image');
        const bgZoomContent = document.querySelector('section.bg-zoom .bg-zoom-content');

        const easings = {};
        // set a small timeout to give the dom same time to addust
        // otherwise the layout will not shown properly.
        setTimeout(() => {
            window.requestAnimationFrame(updateEasings);

            function updateEasings() {
                for (var key in easings) {
                    let easing = easings[key];
                    if (easing.value !== easing.target) {
                        easing.value += (easing.target - easing.value) * easing.speed;
                        if (Math.abs(easing.target - easing.value) < 0.002) {
                            easing.value = easing.target;
                        }
                        if (easing.hasOwnProperty('element')) {
                            easing.element.style.setProperty('--' + key, easing.value + easing.unit)
                        } else {
                            document.documentElement.style.setProperty('--' + key, easing.value + easing.unit)
                        }
                    }
                }
                window.requestAnimationFrame(updateEasings);
            }

            ScrollTrigger.defaults({
                // markers:true,
                start: "top top",
                end: "bottom top"
            });

            easings['bg-zoom-scale'] = {
                target: 0,
                value: 0,
                unit: '',
                speed: 0.07,
                element: bgZoomBackground,
            };
            easings['bg-zoom-opacity'] = {
                target: 0,
                value: 0,
                unit: '',
                speed: 0.07,
                element: bgZoomBackground,
            };

            easings['bg-zoom-content-scale'] = {
                target: 0,
                value: 0,
                unit: '',
                speed: 0.07,
                element: bgZoomContent,
            };

            ScrollTrigger.create({
                id: 'bg-zoom',
                trigger: '.bg-zoom-scrolltrigger',
                onEnter: self => {
                    bgZoom.classList.add('started', 'active');
                },
                onUpdate: self => {
                    easings['bg-zoom-scale'].target = 0.1 + 0.9 * normalizeProgress(self.progress, 0, 0.7);
                    easings['bg-zoom-opacity'].target = 0.1 + 0.9 * normalizeProgress(self.progress, 0, 0.5);
                    easings['bg-zoom-content-scale'].target = 0.2 + 0.6 * normalizeProgress(self.progress, 0, 0.5);
                    bgZoom.classList.toggle('bg-zoom-text-visible', self.progress > 0.3);
                },
                onLeave: self => {
                    easings['bg-zoom-scale'].target = 1.01;
                    bgZoom.classList.add('has-left');
                    bgZoom.classList.remove('active');
                },
                onEnterBack: self => {
                    bgZoom.classList.remove('has-left');
                    bgZoom.classList.add('started', 'active');
                },
                onLeaveBack: self => {
                    bgZoom.classList.remove('active');
                },
            });
        }, 250);

    }, [height]);

    return (
        <section data-section="automate your processes" ref={ref}>
            <div className="bg-zoom-scrolltrigger"></div>
            <section className="bg-zoom bg-white flex-center flex-column px-3">
                <div className="background-image"></div>
                <div className="bg-zoom-content">
                    <h1><FormattedMessage id="automate-your-processes" /></h1>
                    <h3><FormattedMessage id="automate-your-processes-info" /></h3>
                    <h4><FormattedMessage id="automate-your-processes-info-sec" /></h4>
                    <div className="btn-srs-trans-outlined gallery-btn mt-4">
                        <FormattedMessage id="scroll-down" /> <ArrowFatLinesDown weight='fill' size={20} />
                    </div>
                </div>
            </section>
        </section>
    );
};

export default SectionWhichPlan;
