import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { normalizeProgress } from '../../components/helpers';
// import MouseFollower from '../../components/helpers/mouse.follower';


import Logo from '../../assets/images/Logo_SmartRentalSoltution_diap.png'

const SectionAbout = ({
    pageContent,
    height,
}) => {

    gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);

    useEffect(() => {
        const element = ref.current;
        const logoAndParagraph = element;
        const logoAndParagraphBackgroundImage = element.querySelector('section.logo-and-paragraph .background-image');

        // set a small timeout to give the dom same time to addust
        // otherwise the layout will not shown properly.
        setTimeout(() => {
            const easings = {};
            window.requestAnimationFrame(updateEasings);

            function updateEasings() {
                for (var key in easings) {
                    let easing = easings[key];
                    if (easing.value !== easing.target) {
                        easing.value += (easing.target - easing.value) * easing.speed;
                        if (Math.abs(easing.target - easing.value) < 0.002) {
                            easing.value = easing.target;
                        }
                        if (easing.hasOwnProperty('element')) {
                            easing.element.style.setProperty('--' + key, easing.value + easing.unit)
                        } else {
                            document.documentElement.style.setProperty('--' + key, easing.value + easing.unit)
                        }
                    }
                }
                window.requestAnimationFrame(updateEasings);
            }

            ScrollTrigger.defaults({
                // markers:true,
                start: "top top",
                end: "bottom top"
            });

            easings['logo-and-paragraph-background-opacity'] = {
                target: 0,
                value: 0,
                unit: '',
                speed: 0.07,
                element: logoAndParagraphBackgroundImage,
            };

            ScrollTrigger.create({
                id: 'logo-and-paragraph',
                trigger: '.logo-and-paragraph-scrolltrigger',
                onEnter: self => {
                    logoAndParagraph.classList.add('started', 'active');
                },
                onUpdate: self => {
                    easings['logo-and-paragraph-background-opacity'].target = normalizeProgress(self.progress, 0.1, 0.9);
                    logoAndParagraph.classList.add('logo-and-paragraph-text-visible', self.progress > 0.3);
                },
                onLeave: self => {
                    logoAndParagraph.classList.add('has-left');
                    logoAndParagraph.classList.remove('active');
                },
                onEnterBack: self => {
                    logoAndParagraph.classList.remove('has-left');
                    logoAndParagraph.classList.add('started', 'active');
                },
                onLeaveBack: self => {
                    logoAndParagraph.classList.remove('active');
                },
            });
        }, 250);

    }, [height]);

    const renderHTMLContent = (htmlContent) =>
        React.createElement('div', {
            dangerouslySetInnerHTML: { __html: htmlContent },
        });


    return (
        <React.Fragment>
            <div className="logo-and-paragraph-scrolltrigger"></div>
            <section className="logo-and-paragraph" ref={ref}>
                <div
                    className="background-image"
                    style={{ backgroundImage: `url(${pageContent.pageHeaderImage.url})` }}
                    data-style={{ backgroundImage: pageContent.pageHeaderImage.url }}></div>


                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 d-lg-flex justify-content-between align-items-center flex-row p-4">
                            <img src={Logo} className="srs-logo"
                                alt="" />
                        </div>

                        <div className="col-md-8 col-sm-12 justify-content-between align-items-center flex-row p-4">
                            <div className="transparant-container">
                                <h1>{pageContent.pageHeaderTitle}</h1>
                                <div className="cms-content paragraph">
                                    {renderHTMLContent(pageContent.pageHeaderContent.html)}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </React.Fragment>

    );
};

export default SectionAbout;