import React, { useRef, useEffect } from 'react';
import { FormattedMessage } from "react-intl";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { getFadingValues, normalizeProgress } from '../../components/helpers';

const Section0To100 = ({
    pageSteps,
    height,
    ...props
}) => {

    gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);


    useEffect(() => {

        const element = ref.current;
        // set a small timeout to give the dom same time to addust
        // otherwise the layout will not shown properly.
        setTimeout(() => {
            const nulNaarHonderd = element.querySelector('section.nul-naar-honderd');
            const nulNaarHonderdTeller = element.querySelector('section.nul-naar-honderd .teller');
            // const useMobileScrollTriggers = window.innerWidth < 1000;

            const easings = {};

            function updateEasings() {
                for (var key in easings) {
                    let easing = easings[key];
                    if (easing.value !== easing.target) {
                        easing.value += (easing.target - easing.value) * easing.speed;
                        if (Math.abs(easing.target - easing.value) < 0.002) {
                            easing.value = easing.target;
                        }
                        if (easing.hasOwnProperty('element')) {
                            easing.element.style.setProperty('--' + key, easing.value + easing.unit)
                        } else {
                            document.documentElement.style.setProperty('--' + key, easing.value + easing.unit)
                        }
                    }
                }
                window.requestAnimationFrame(updateEasings);
            };

            window.requestAnimationFrame(updateEasings);

            ScrollTrigger.defaults({
                // markers:true,
                start: "top top",
                end: "bottom top"
            });

            easings['nul-100-background-scale'] = {
                target: 1,
                value: 1,
                unit: '',
                speed: 0.07,
                element: nulNaarHonderd,
            };

            ScrollTrigger.create({
                id: 'nul-naar-honderd',
                trigger: '.nul-naar-honderd-scrolltrigger',
                onEnter: self => {
                    nulNaarHonderd.classList.add('started', 'active');
                },
                onUpdate: self => {
                    let progress = normalizeProgress(self.progress, 0.05, 0.8);
                    let percentage = Math.round(progress * 100);
                    nulNaarHonderdTeller.innerHTML = percentage;
                    easings['nul-100-background-scale'].target = 1 + normalizeProgress(self.progress, 0, 1) * 0.15;
                },
                onLeave: self => {
                    nulNaarHonderd.classList.add('has-left');
                    nulNaarHonderd.classList.remove('active');
                },
                onEnterBack: self => {
                    nulNaarHonderd.classList.add('started', 'active');
                    nulNaarHonderd.classList.remove('has-left');
                },
                onLeaveBack: self => {
                    nulNaarHonderd.classList.remove('active');
                },
                // markers: true,
            });

            // gsap.utils.toArray('.sticky-reveal').forEach((element, i) => {
            //     ScrollTrigger.create({
            //         trigger: element,
            //         start: 'top 12%',
            //         // end: 'top -1200px',
            //         end: useMobileScrollTriggers ? 'top -500px' : 'top -900px',
            //         pin: true,
            //         pinSpacing: true,
            //         onUpdate: self => {

            //             let imageScale = 0.9 + normalizeProgress(self.progress, 0, 0.7) * 0.1;
            //             let imageOpacity = 0.4 + normalizeProgress(self.progress, 0, 0.7) * 0.6;
            //             let imageGradientOpacity = 1 - normalizeProgress(self.progress, 0, 0.7);
            //             self.trigger.style.setProperty('--image-gradient-opacity', imageGradientOpacity);
            //             self.trigger.style.setProperty('--image-scale', imageScale);
            //             self.trigger.style.setProperty('--image-opacity', imageOpacity);

            //             // let textOpacity = 0 + normalizeProgress(self.progress, 0.3, 0.7);
            //             // let textTranslate = 30 - normalizeProgress(self.progress, 0.3, 0.7) * 30;
            //             // self.trigger.style.setProperty('--text-opacity', textOpacity);
            //             // self.trigger.style.setProperty('--text-translate', textTranslate + 'px');
            //         },
            //         onEnter: self => {
            //             setActiveStap(i);
            //         },
            //         onEnterBack: self => {
            //             setActiveStap(i);
            //         },
            //         onLeaveBack: self => {
            //             if (i > 0) {
            //                 setActiveStap(i - 1);
            //             }
            //         },
            //         // markers:true,
            //     });
            // });

            gsap.utils.toArray('.fading-visual').forEach((element, i) => {
                ScrollTrigger.create({
                    trigger: element,
                    start: 'top bottom',
                    end: 'bottom 200px',
                    onUpdate: self => {
                        let values = getFadingValues(self.progress);
                        self.trigger.style.setProperty('--visual-opacity', values.visualOpacity);
                    },
                    // markers:true,
                });
            });

        }, 250);

    }, [height]);


    return (
        <section data-section="nul-to-100" ref={ref}>
            <div className="nul-naar-honderd-scrolltrigger"></div>
            <section className="nul-naar-honderd">
                <div className="background-image"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 order-lg-2 d-flex justify-content-center justify-content-lg-end align-items-center">
                            <div className="teller">0</div>
                        </div>

                        <div className="col-lg-5 p-5 p-lg-0 order-lg-1 d-flex justify-content-center text-center text-lg-start justify-content-lg-start align-items-center">
                            <div className="row">
                                <div className="col-12">
                                    <h4 style={{ "fontSize": "2.3rem", "fontWeight": 600, color: 'var(--color-orange)' }}><FormattedMessage id="home-how-it-works" /></h4>
                                </div>
                                <div className="col-12">
                                    <h3 className="title"><FormattedMessage id="steps-from-0-to-100" />
                                        {/* <br />
                                    <FormattedMessage id="steps-in-5-steps" /> */}
                                    </h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default Section0To100;