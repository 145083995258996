import React from "react";
import accounting from 'accounting-js';

const FormatEuro = ({
    amount = 0,
    symbol = "€",
}) => {
    return (<span>{accounting.formatMoney(amount, { symbol: symbol, format: "%s %v", decimal: ",", thousand: ".", })}</span>);
};

export default FormatEuro;