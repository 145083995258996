import React, { useEffect, useState } from 'react';


const ScrollHint = ({
    checkpoint = 1200,
}) => {
    const [opacity, setOpacity] = useState(0);
    const [animation, setAnimation] = useState('');

    useEffect(() => {
        const ListenerFunction = function () {
            const currentScroll = window.pageYOffset;
            if (currentScroll <= checkpoint) {
                setOpacity(1 - currentScroll / checkpoint);
                setAnimation('none');
            } else {
                setOpacity(0);

            }
        };

        window.addEventListener("scroll", ListenerFunction);
        // setAnimation('none');

        // clean-up
        return () => {
            window.removeEventListener('scroll', ListenerFunction);
        };

    }, [checkpoint]);


    return (
        <div className="scroll-hint" title="Scroll voor meer..." style={{ "opacity": opacity, "animation": animation }}>
            <div className="scroll-ball" style={{ "--speed": "2.8s", "--delay": "2500ms" }}></div>
        </div>

    );
};

export default ScrollHint;