import { useMotionValue } from 'framer-motion';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import clamp from 'lodash/clamp';
import React, { useContext, useLayoutEffect, useRef } from 'react';

const ScrollTriggerContext = React.createContext(null);

const useScrollTrigger = () => useContext(ScrollTriggerContext);

const DEFAULT_OPTIONS = {
    end: '+=100%',
    pin: true,
    scrub: true,
    start: 'top top',
};

const ScrollTriggerProvider = ({
    children,
    debug = false,
    options = {},
}) => {
    const refScrollTrigger = useRef(null);
    const refTimeline = useRef();
    const progress = useMotionValue(0);

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        if (refScrollTrigger.current) {
            refTimeline.current = gsap.timeline({
                scrollTrigger: {
                    ...DEFAULT_OPTIONS,
                    ...options,
                    markers: debug,
                    trigger: refScrollTrigger.current,
                    onUpdate: (instance) => {
                        progress.set(clamp(instance.progress, 0, 1));
                        // setActiveStep(counter);
                    },
                    // onEnter: self => {
                    //     setActiveStep(counter + 1);
                    // },
                    // onEnterBack: self => {
                    //     setActiveStep(counter + 1);
                    // },
                    // onLeaveBack: self => {
                    //     if (i > 0) {
                    //         setActiveStap(i - 1);
                    //     }
                    // },
                },
            });
        }

        return () => {
            // Kill and clear the timeline and scrolltrigger instance when updated/unmounted.
            refTimeline.current?.scrollTrigger?.kill();
            refTimeline.current?.kill();
            refTimeline.current?.clear();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debug, options, progress]);

    return (
        <div ref={refScrollTrigger}>
            <ScrollTriggerContext.Provider value={progress}>
                {children}
            </ScrollTriggerContext.Provider>
        </div>
    );
};

export { ScrollTriggerProvider, useScrollTrigger };
