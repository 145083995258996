import { motion, useTransform } from 'framer-motion';
import { FormattedMessage, } from 'react-intl';
import React from 'react';
import { useScrollTrigger } from './ScrollTriggerProvider';

// https://codesandbox.io/p/sandbox/framer-motion-gsap-scrolltrigger-hf1lw
const Screen = ({
    fadeIn = true,
    fadeOut = true,
    data,
    index,

}) => {

    const progress = useScrollTrigger();

    // Fade background from start to finish.
    // const bg = useTransform(progress, [0, 1], [colorStart, colorEnd]);

    // Fade title in halfway and out at the end.
    const titleOpacity = useTransform(progress, [0, 0.5, 1], [fadeIn ? 0 : 1, 1, fadeOut ? 0 : 1]);

    // Animate progress bar to completion.
    // const bgProgress = useTransform(progress, [0, 1], ['100% 0%', '100% 100%']);

    const renderHTMLContent = (htmlContent) =>
        React.createElement('div', {
            dangerouslySetInnerHTML: { __html: htmlContent },
        });

    return (
        <div
            className="screen"
        // style={{ opacity: titleOpacity }}
        // style={{ backgroundColor: bg }}
        // initial={{ opacity: 0 }}
        // whileInView={{ opacity: 1 }}
        >
            {/* <motion.h2 style={{ opacity: titleOpacity }}>{title}</motion.h2> */}
            {/* <div className={index === 0 ? "spacer-big" : "spacer-very-big spacer-lg-down-big"}></div> */}

            <motion.div className="row sticky-reveall"
                style={{ opacity: titleOpacity }}>
                <div className={"offset-lg-1 col-lg-5"}>
                    <div className="sticky-text">

                        <div className="title-with-tellerr">
                            <div className="stap-teller"><FormattedMessage id="step" /> {index + 1}</div>
                            <h3>{data.contentBlockH2Title}</h3>
                        </div>

                        <div>
                            {/* <h1>Stap 1</h1> */}
                            <h2>{renderHTMLContent(data.title03)}</h2>
                            <div>{renderHTMLContent(data.contentBlockInhoud.html)}</div>
                        </div>

                    </div>
                </div>

                <div className={"col-lg-6 flex-center mt-4 mt-lg-auto"}>
                    <div className="sticky-image">
                        <img
                            // style={{ width: 400 }}
                            // initial={{ opacity: 0.2 }}
                            // animate={{ opacity: 1 }}
                            // exit={{ opacity: 0 }}
                            src={data.contentBlockAfbeelding?.url}
                            alt={data.contentBlockAfbeelding?.altTekst}
                            className="picture stap-1-visual fading-visuall" />
                    </div>
                </div>
            </motion.div>

        </div>
    );
};

export default Screen;
