import React from 'react';
import { NavLink, } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { Tooltip } from 'antd';
import { EnvelopeSimple, House, LinkedinLogo, PhoneCall, SquaresFour, User } from '@phosphor-icons/react';


const Menu = ({
    title,
    locale,
    currency,
    setCurrentLanguage,
    setCurrency,
    history,
    ...props

}) => {

    const displayLanguage = (lang) => {
        switch (lang) {
            case "nl":
                return "en";
            case "en":
                return "nl";
            default:
                return lang;
        };
    };

    const toggleNavigation = () => {
        document.documentElement.classList.toggle('navigation-visible');
        if (!document.documentElement.classList.contains('navigation-visible')) {
            document.documentElement.classList.add('navigation-closing');
            setTimeout(() => {
                document.documentElement.classList.remove('navigation-closing');
            }, 750);
        }
    };

    const toggleLanguage = () => {
        switch (locale) {
            case "nl":
                setCurrentLanguage('en');
                break;
            case "en":
                setCurrentLanguage('nl');
                break;
            default:
                break;
        };
    };

    return (
        <React.Fragment>

            <nav className="menu-bar">
                <span className="menu-title text-uppercase text-end">
                    {title && (
                        title
                    )}
                </span>
                <div className="menu-hamburger" onClick={() => toggleNavigation()}>
                    <div role="button">
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>

                <ul className="menu-items">

                    <li><NavLink to="/" exact activeClassName="selected"><House size={30} /></NavLink></li>
                    <li><NavLink to="/products" activeClassName="selected"><SquaresFour size={30} /></NavLink></li>
                    <li>
                        <Tooltip title={<FormattedMessage id="user-login" />}>
                            <a href={process.env.REACT_APP_ADMIN} target="_blank" rel="noreferrer">
                                <User size={30} />
                            </a>
                        </Tooltip>
                    </li>

                    {/* {pathname.indexOf("/products") === 0 &&
                        (<li className="d-none d-lg-block"><span onClick={toggleCurrency}>
                            {currency === "BTC" ? (<span><EuroSVG /></span>) : (<BitcoSVG />)}
                        </span></li>)} */}

                    <li onClick={toggleLanguage}
                        className="d-none d-lg-block text-center"
                        style={{ alignContent: 'space-around' }}>
                        <span className="text-uppercase">{displayLanguage(locale)}</span>
                    </li>
                    <li className="mobile-only">

                        {/* <NavDropdown as="span" drop="down" title="...">
                            {pathname.indexOf("/car") === 0 &&
                                (
                                    <NavDropdown.Item>
                                        <span onClick={toggleCurrency}>
                                            {currency === "BTC" ? (<EuroSVG />) : (<BitcoSVG />)}
                                        </span>
                                    </NavDropdown.Item>
                                )
                            }

                            <NavDropdown.Item onClick={toggleLanguage}><span className="text-uppercase">{displayLanguage(locale)}</span></NavDropdown.Item>
                        </NavDropdown> */}
                    </li>
                </ul>

                <ul className="menu-contact">
                    <li><a href={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}><PhoneCall size={30} /></a></li>
                    {/* <li><a href="https://wa.me/31651096144"><WhatsappSVG /></a></li> */}
                    {/* <li><a href={`whatsapp://send?phone=${process.env.REACT_APP_WHATSAPP}`}><WhatsappSVG /></a></li> */}
                    <li
                        onClick={() => { history('/contact') }}
                    ><EnvelopeSimple size={30} /></li>
                    <li><a href="https://www.linkedin.com/company/smart-rental-soluitions/" target="_blank" rel="noopener noreferrer"><LinkedinLogo size={30} /></a></li>
                </ul>
            </nav>

            <div className="menu-bar-spacer"></div>

            <nav className="navigation">
                <NavLink to="/" className="panel" onClick={toggleNavigation}>
                    <div className="bg nav-tour"></div>
                    <div className="label"><FormattedMessage id="home" /></div>
                </NavLink>

                <NavLink to="/products" className="panel" onClick={toggleNavigation}>
                    <div className="bg nav-aanbod"></div>
                    <div className="label"><FormattedMessage id="products" /></div>
                </NavLink>

                <NavLink to="/plugins" className="panel" onClick={toggleNavigation}>
                    <div className="bg nav-plugins"></div>
                    <div className="label"><FormattedMessage id="marketplace" /></div>
                </NavLink>

                <NavLink to="/contact" className="panel" onClick={toggleNavigation}>
                    <div className="bg nav-info"></div>
                    <div className="label"><FormattedMessage id="contact" /></div>
                </NavLink>

                <NavLink to="/support" className="panel" onClick={toggleNavigation}>
                    <div className="bg nav-support"></div>
                    <div className="label"><FormattedMessage id="support" /></div>
                </NavLink>

                <a href={process.env.REACT_APP_ADMIN} target="_blank" rel="noreferrer" className="panel" onClick={toggleNavigation}>
                    <div className="bg nav-login"></div>
                    <div className="label"><FormattedMessage id="login" /></div>
                </a>
            </nav>

            {/* {isContactModalOpen && (
                <ContactModal
                    modelOpenHandler={setIscontactModalOpen}
                    modelIsOpen={isContactModalOpen}
                />
            )} */}
        </React.Fragment >
    );
};

export default Menu;