import React from 'react';

const Spinner = ({
    className = "",
}) => {
    return (
        <div className="preloader">
            <div className={`loader ${className}`}>
                <div className="ytp-spinner">
                    <div className="ytp-spinner-container">
                        <div className="ytp-spinner-rotator">
                            <div className="ytp-spinner-left">
                                <div className="ytp-spinner-circle"></div>
                            </div>
                            <div className="ytp-spinner-right">
                                <div className="ytp-spinner-circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        // <div className="pb-5 pt-5 text-center" data-testid="spinner">
        //     <div className="col-12 mb-r text-center h-100 align-items-center">
        //         <div className={`spinner-loading align-items-center ${className}`}>
        //             <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-2x"></FontAwesomeIcon>
        //             <br></br>
        //             Loading...
        //         </div>
        //     </div>
        // </div>
    );
};

export default Spinner;